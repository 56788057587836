<template>
  <v-container fluid>
    <v-card outlined>
      <v-card-title>
        <v-row>
          <v-col cols="12" class="text-center">
            <h4 class="title">Alterar Recurso</h4>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="4" class="text-center">
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :autofocus="true"
                  outlined
                  v-model="begin"
                  label="Data de início"
                  prepend-inner-icon="calendar_today"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @keypress.esc="closeDialog()"
                  @keypress.enter="putAsyncAvailableResource()"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="begin"
                @input="menu2 = false"
                locale="br"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="4" class="text-center">
            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  v-model="end"
                  label="Data de término"
                  prepend-inner-icon="calendar_today"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @keypress.esc="closeDialog()"
                  @keypress.enter="putAsyncAvailableResource()"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="end"
                @input="menu1 = false"
                locale="br"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="4" class="text-center">
            <v-text-field
              outlined
              v-model="value"
              label="Valor do Recurso"
              prepend-inner-icon="attach_money"
              :rules="[numberRule]"
              @keypress.esc="closeDialog()"
              @keypress.enter="putAsyncAvailableResource()"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-progress-circular
              indeterminate
              color="green"
              v-show="disable == true"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row justify="center" align="center" wrap>
          <v-col cols="12" class="text-center">
            <v-btn
              color="green"
              outlined
              :disabled="disable"
              @click="putAsyncAvailableResource()"
              @keypress.esc="closeDialog()"
              >Alterar</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "AvailableResourceDialog",
  props: ["object"],
  data() {
    return {
      disable: false,
      resource: {},
      begin: "",
      end: "",
      value: 0,
      menu: false,
      menu1: false,
      menu2: false,
      modal: false,
      numberRule: (v) => {
        if (!isNaN(parseFloat(v))) return true;
        return "O campo é numérico!";
      },
    };
  },

  created() {
    this.resource = this.object;
    this.value = parseFloat(this.resource.value);
    this.begin = this.resource.begin.split("/");
    this.begin = this.begin[2] + "-" + this.begin[1] + "-" + this.begin[0];
    this.end = this.resource.end.split("/");
    this.end = this.end[2] + "-" + this.end[1] + "-" + this.end[0];
  },

  methods: {
    ...mapActions("availableResource", ["ActionPutAvailableResource"]),

    async putAsyncAvailableResource() {
      if (this.begin != "" && this.end != "" && this.value != "" && this.resource.id != "") {
        let payload = {
          id: this.resource.id,
          begin: this.begin,
          end: this.end,
          value: parseFloat(this.value),
        };

        try {
          let res = await this.ActionPutAvailableResource(payload);
          this.response = res.body;
          this.closeDialogCreated();
        } catch (e){
          console.error('erro ao alterar recurso', e);
        }
      }
    },

    closeDialog() {
      this.resource = {};
      this.begin = "";
      this.end = "";
      this.value = 0;
      this.$emit("close-dialog", null);
    },

    closeDialogCreated() {
      this.resource = {};
      this.begin = "";
      this.end = "";
      this.value = 0;
      this.$emit("close-dialog", this.response);
    },
  },

  watch: {
    object(newObject) {
      this.resource = newObject;
      this.value = parseFloat(this.resource.value);
      this.begin = this.resource.begin.split("/");
      this.begin = this.begin[2] + "-" + this.begin[1] + "-" + this.begin[0];
      this.end = this.resource.end.split("/");
      this.end = this.end[2] + "-" + this.end[1] + "-" + this.end[0];
    },
  },
};
</script>

<style>
</style>