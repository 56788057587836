<template>
  <v-container fluid>
    <v-card outlined>
      <v-card-title>
        <v-row>
          <v-col cols="12" class="text-center">
            <h4 class="title">Inserir Nova Agência</h4>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="text-center">
            <v-text-field
              prepend-inner-icon="business"
              v-model="name"
              label="nome"
              outlined
              @keypress.enter="postAsyncAgency"
              :disabled="disable"
              :autofocus="true"
              :rules="[rules.length]"
              counter="100"
            ></v-text-field>

            <v-text-field
              prepend-inner-icon="location_on"
              v-model="address"
              label="endereço"
              outlined
              @keypress.enter="postAsyncAgency"
              :disabled="disable"
              counter="200"
            ></v-text-field>

            <v-text-field
              prepend-inner-icon="email"
              v-model="email"
              label="e-mail"
              outlined
              @keypress.enter="postAsyncAgency"
              :disabled="disable"
              counter="100"
            ></v-text-field>

            <v-text-field
              prepend-inner-icon="business"
              v-model="cnpj"
              label="cnpj"
              outlined
              @keypress.enter="postAsyncAgency"
              :disabled="disable"
              counter="20"
            ></v-text-field>

            <v-text-field
              prepend-inner-icon="phone"
              v-model="phone"
              label="telefone"
              outlined
              @keypress.enter="postAsyncAgency"
              :disabled="disable"
              counter="20"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-progress-circular
              indeterminate
              color="green"
              v-show="disable == true"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row justify="center" align="center" wrap>
          <v-col cols="6" class="text-center">
            <v-btn
              color="red"
              outlined
              @click="closeDialog()"
              :disabled="disable"
              >Cancelar</v-btn
            >
          </v-col>
          <v-col cols="6" class="text-center">
            <v-btn
              color="green"
              outlined
              @click="postAsyncAgency"
              :disabled="disable"
              >Adicionar</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "AgencyDialog",
  props: ["agencyName"],
  data() {
    return {
      disable: false,
      name: "",
      address: "",
      email: "",
      phone: "",
      cnpj: "",
      rules: {
        length: (v) =>
          (v || "").length <= 100 ||
          "O nome da agência deve ser conter menos que 100 caracteres!",
      },
    };
  },

  created() {},

  methods: {
    ...mapActions("fundingAgency", ["ActionPostFundingAgency"]),

    closeDialog() {
      this.$emit("close-dialog");
    },

    async postAsyncAgency() {
      try {
        if (this.name !== "" && this.name.length <= 100) {
          this.disable = true;
          let payload = {
            name: this.name,
            address: this.address,
            cnpj: this.cnpj,
            phone: this.phone,
            email: this.email
          };
          await this.ActionPostFundingAgency(payload);
          this.disable = false;
          this.name = "";
          this.address = "";
          this.phone = "";
          this.cnpj = "";
          this.email = "";
          this.closeDialog();
        }
      } catch (error) {
        console.error("erro ao criar nova agência", error);
      }
    },
  },
};
</script>