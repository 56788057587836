var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-card",
        { attrs: { outlined: "" } },
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "12" } },
                    [
                      _c("h4", { staticClass: "title" }, [
                        _vm._v("Remover Agência")
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "12" } },
                    [
                      _c("p", [
                        _vm._v("Tem certeza que deseja remover a agência "),
                        _c("strong", [_vm._v(_vm._s(_vm.agency.name))]),
                        _vm._v("?")
                      ]),
                      _c("p", [
                        _vm._v(
                          " A operação não poderá ser desfeita e todas as bolsas ligadas a mesma serão removidas da base de dados! "
                        )
                      ])
                    ]
                  ),
                  _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "12" } },
                    [
                      _c("v-progress-circular", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.disable == true,
                            expression: "disable == true"
                          }
                        ],
                        attrs: { indeterminate: "", color: "green" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c(
                "v-row",
                { attrs: { justify: "center", align: "center", wrap: "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "6" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "green",
                            outlined: "",
                            disabled: _vm.disable
                          },
                          on: {
                            click: function($event) {
                              return _vm.delAsyncAgency()
                            },
                            keypress: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k($event.keyCode, "esc", 27, $event.key, [
                                  "Esc",
                                  "Escape"
                                ])
                              ) {
                                return null
                              }
                              return _vm.closeDialog()
                            }
                          }
                        },
                        [_vm._v("Remover")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "6" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "red",
                            outlined: "",
                            disabled: _vm.disable
                          },
                          on: {
                            click: function($event) {
                              return _vm.closeDialog()
                            },
                            keypress: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k($event.keyCode, "esc", 27, $event.key, [
                                  "Esc",
                                  "Escape"
                                ])
                              ) {
                                return null
                              }
                              return _vm.closeDialog()
                            }
                          }
                        },
                        [_vm._v("Cancelar")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }