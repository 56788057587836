var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { attrs: { dense: "", "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "agencia", attrs: { cols: "11" } }, [
            _c("h3", { staticClass: "ml-3" }, [_vm._v("Agências de Fomento")])
          ]),
          _c(
            "v-col",
            { staticClass: "agencia", attrs: { cols: "1" } },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { left: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    outlined: "",
                                    small: "",
                                    color: "green"
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.dialog = !_vm.dialog
                                    }
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v("add")
                              ])
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v("Adicionar nova agência")])]
              )
            ],
            1
          ),
          _vm._l(_vm.agencies, function(agency) {
            return _c(
              "v-flex",
              { key: agency.id, attrs: { "mt-5": "" } },
              [_c("agency", { attrs: { fundingAgency: agency } })],
              1
            )
          }),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "800" },
              on: {
                keydown: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "esc", 27, $event.key, [
                      "Esc",
                      "Escape"
                    ])
                  ) {
                    return null
                  }
                  _vm.dialog = false
                }
              },
              model: {
                value: _vm.dialog,
                callback: function($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog"
              }
            },
            [_c("AgencyDialog", { on: { "close-dialog": _vm.closeDialog } })],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }