<template>
  <div>
    <v-card color="grey lighten-4">
      <v-card-text>
        <v-flex>
          <v-card outlined>
            <v-card-text>
              <v-row>
                <v-col cols="9" class="agencia">
                  <v-row class="ml-3">
                    <label
                      ><strong>{{ fundingAgency.name }}</strong></label
                    >
                  </v-row>
                  <v-row class="ml-3">
                    <label>End.: {{ fundingAgency.address }}</label>
                  </v-row>
                  <v-row class="ml-3">
                    <label>E-mail.: {{ fundingAgency.email }}</label>
                  </v-row>
                  <v-row class="ml-3">
                    <label>Tel.: {{ fundingAgency.phone }}</label>
                  </v-row>
                  <v-row class="ml-3">
                    <label>CNPJ: {{ fundingAgency.cnpj }}</label>
                  </v-row>
                </v-col>
                <v-col cols="1" class="agencia">
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        small
                        color="red"
                        @click="openDialogDel(fundingAgency)"
                        ><v-icon small>delete</v-icon></v-btn
                      >
                    </template>
                    <span>Remover agência de fomento</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="1" class="agencia">
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        small
                        color="orange"
                        @click="openDialog(fundingAgency)"
                        ><v-icon small>edit</v-icon></v-btn
                      >
                    </template>
                    <span>Editar a agência de fomento</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="1" class="agencia">
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        small
                        color="green"
                        @click="openDialogAR(fundingAgency)"
                        ><v-icon small>add</v-icon></v-btn
                      >
                    </template>
                    <span>Adicionar recurso financeiro para a agência</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-divider></v-divider>
        </v-flex>
        <v-flex>
          <v-data-table
            v-bind:headers="headers"
            v-bind:items="list"
            hide-default-footer
            no-data-text="Não há recursos cadastrados"
            :loading="false"
            :calculate-widths="true"
          >
            <template v-slot:item="props">
              <tr>
                <td class="text-xs-left">{{ props.item.begin }}</td>
                <td class="text-xs-left">{{ props.item.end }}</td>
                <td class="text-xs-left">{{ props.item.value }}</td>
                <td class="text-xs-left">
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        x-small
                        color="orange"
                        @click="openDialogUpdate(props.item)"
                        ><v-icon small>edit</v-icon></v-btn
                      >
                    </template>
                    <span>Editar recurso financeiro</span>
                  </v-tooltip>
                </td>
                <td>
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        x-small
                        color="red"
                        @click="openDialogDelAR(props.item)"
                        ><v-icon small>delete</v-icon></v-btn
                      >
                    </template>
                    <span>Excluir recurso financeiro</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialog" max-width="800" @keydown.esc="dialog = false">
      <AgencyDialogUpdate
        v-on:close-dialog="closeDialog"
        :object="agency"
      ></AgencyDialogUpdate>
    </v-dialog>

    <v-dialog
      v-model="dialogDel"
      max-width="800"
      @keydown.esc="dialogDel = false"
    >
      <AgencyDialogDel
        v-on:close-dialog="closeDialogDel"
        :object="agency"
      ></AgencyDialogDel>
    </v-dialog>

    <v-dialog
      v-model="dialogAR"
      max-width="800"
      @keydown.esc="dialogAR = false"
    >
      <AvailableResourceDialog
        v-on:close-dialog="closeDialogAR"
        :object="agency"
      ></AvailableResourceDialog>
    </v-dialog>

    <v-dialog
      v-model="dialogDelAR"
      max-width="800"
      @keydown.esc="dialogDelAR = false"
    >
      <AvailableResourceDialogDel
        v-on:close-dialog="closeDialogDelAR"
        :object="availableResource"
      ></AvailableResourceDialogDel>
    </v-dialog>

    <v-dialog
      v-model="dialogUpdate"
      max-width="800"
      @keydown.esc="dialogUpdate = false"
    >
      <AvailableResourceDialogUpdate
        v-on:close-dialog="closeDialogUpdate"
        :object="availableResource"
      ></AvailableResourceDialogUpdate>
    </v-dialog>
  </div>
</template>

<script>
import { formatDate } from "@/utils/formatDate";
import AgencyDialogDel from "./AgencyDialogDel";
import AgencyDialogUpdate from "./AgencyDialogUpdate";
import AvailableResourceDialog from "@/components/admin/available_resource/AvailableResourceDialog";
import AvailableResourceDialogDel from "@/components/admin/available_resource/AvailableResourceDialogDel";
import AvailableResourceDialogUpdate from "@/components/admin/available_resource/AvailableResourceDialogUpdate";

export default {
  name: "Agency",
  props: ["fundingAgency"],
  components: {
    AgencyDialogDel,
    AgencyDialogUpdate,
    AvailableResourceDialog,
    AvailableResourceDialogDel,
    AvailableResourceDialogUpdate,
  },
  data() {
    return {
      headers: [
        { sortable: true, text: "Início", value: "begin", align: "left" },
        { sortable: true, text: "Fim", value: "end", align: "left" },
        { sortable: true, text: "Valor R$", value: "value", align: "left" },
        { sortable: false, text: "Editar", value: "edit", align: "left" },
        { sortable: false, text: "Excluir", value: "delete", align: "left" },
      ],
      list: [],
      dialog: false,
      dialogDel: false,
      dialogAR: false,
      dialogDelAR: false,
      dialogUpdate: false,
      agency: {},
      availableResource: {},
    };
  },
  created() {
    if (this.fundingAgency.availableResources) {
      this.fundingAgency.availableResources.map((ar) => {
        this.list.push({
          id: ar.id,
          begin: formatDate.dmy(ar.begin),
          end: formatDate.dmy(ar.end),
          value: ar.value,
        });
      });
    }
  },

  methods: {
    closeDialog() {
      this.dialog = false;
      this.agency = {};
    },

    openDialog(agency) {
      this.agency = agency;
      this.dialog = true;
    },

    closeDialogDel() {
      this.dialogDel = false;
      this.agency = {};
    },

    openDialogDel(agency) {
      this.agency = agency;
      this.dialogDel = true;
    },

    openDialogAR(agency) {
      this.agency = agency;
      this.dialogAR = true;
    },

    closeDialogAR(resource) {
      this.list.push({
        id: resource.id,
        begin: formatDate.dmy(resource.begin),
        end: formatDate.dmy(resource.end),
        value: resource.value,
      });
      this.dialogAR = false;
      this.agency = {};
    },

    openDialogDelAR(item) {
      this.availableResource = item;
      this.dialogDelAR = true;
    },

    closeDialogDelAR(id) {
      this.dialogDelAR = false;
      if (id !== null) {
        this.list = this.list.filter((e) => e.id != id);
      }
    },

    openDialogUpdate(item) {
      this.availableResource = item;
      this.dialogUpdate = true;
    },

    closeDialogUpdate(item) {
      this.dialogUpdate = false;
      this.list = this.list.filter((e) => e.id != item.id);
      this.list.push({
        id: item.id,
        begin: formatDate.dmy(item.begin),
        end: formatDate.dmy(item.end),
        value: item.value,
      });
    },
  },
};
</script>


<style scoped>
.agencia {
  margin: 0%;
  padding: 0%;
}
</style>