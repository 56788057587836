<template>
  <div>
    <v-row dense no-gutters>
      <v-col class="agencia" cols="11">
        <h3 class="ml-3">Agências de Fomento</h3>
      </v-col>
      <v-col class="agencia" cols="1">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              outlined
              small
              color="green"
              @click="dialog = !dialog"
              ><v-icon small>add</v-icon></v-btn
            >
          </template>
          <span>Adicionar nova agência</span>
        </v-tooltip>
      </v-col>

      <v-flex v-for="agency in agencies" :key="agency.id" mt-5>
        <agency :fundingAgency="agency"></agency>
      </v-flex>

      <v-dialog v-model="dialog" max-width="800" @keydown.esc="dialog = false">
        <AgencyDialog v-on:close-dialog="closeDialog"></AgencyDialog>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AgencyDialog from "./AgencyDialog";
import Agency from "./Agency";

export default {
  name: "FundingAgency",
  components: {
    Agency,
    AgencyDialog,
  },
  data() {
    return {
      dialog: false,
      disabled: false,
      name: "",
      agencies: [],
    };
  },

  created() {
    this.getAsyncFundingAgencies();
  },

  methods: {
    ...mapActions("fundingAgency", ["ActionGetFundingAgencies"]),

    closeDialog: function () {
      this.dialog = false;
    },

    async getAsyncFundingAgencies() {
      try {
        await this.ActionGetFundingAgencies();
      } catch (err) {
        console.log(err);
      }
    },
  },

  computed: {
    ...mapState("fundingAgency", ["fundingAgencies"]),
  },

  watch: {
    fundingAgencies(newValue) {
      if (newValue) {
        this.agencies = newValue;
      }
    },
  },
};
</script>                                                                 

<style scoped>
.agencia {
  margin: 0%;
  padding: 0%;
}
</style>