<template>
  <v-container fluid>
    <v-card outlined>
      <v-card-title>
        <v-row>
          <v-col cols="12" class="text-center">
            <h4 class="title">Remover Agência</h4>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="text-center">
            <p>Tem certeza que deseja remover a agência <strong>{{ agency.name }}</strong>?</p>
            <p>
              A operação não poderá ser desfeita e todas as bolsas ligadas a
              mesma serão removidas da base de dados!
            </p>
          </v-col>

          <v-col cols="12" class="text-center">
            <v-progress-circular
              indeterminate
              color="green"
              v-show="disable == true"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row justify="center" align="center" wrap>
           <v-col cols="6" class="text-center">
            <v-btn
              color="green"
              outlined
              @click="delAsyncAgency()"
              @keypress.esc="closeDialog()"
              :disabled="disable"
              >Remover</v-btn
            >
          </v-col>
          <v-col cols="6" class="text-center">
            <v-btn
              color="red"
              outlined
              @click="closeDialog()"
              @keypress.esc="closeDialog()"
              :disabled="disable"
              >Cancelar</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "AgencyDialogDel",
  props: ["object"],
  data() {
    return {
      disable: false,
      agency: {},
    };
  },
  created() {
    this.agency = this.object;
  },
  methods: {
    ...mapActions("fundingAgency", ["ActionDelFundingAgency"]),

    async delAsyncAgency() {
      try{
        this.disable = true;
        await this.ActionDelFundingAgency(this.agency.id);
        this.disable = false;
        this.closeDialog();
      } catch (e){
        console.error('Erro ao remover agência!')
      }
    },

    closeDialog() {
      this.agency = {};
      this.$emit("close-dialog");
    },
  },
  watch: {
    object(v) {
      this.agency = v;
    },
  },
};
</script>