var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { color: "grey lighten-4" } },
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-flex",
                [
                  _c(
                    "v-card",
                    { attrs: { outlined: "" } },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "agencia",
                                  attrs: { cols: "9" }
                                },
                                [
                                  _c("v-row", { staticClass: "ml-3" }, [
                                    _c("label", [
                                      _c("strong", [
                                        _vm._v(_vm._s(_vm.fundingAgency.name))
                                      ])
                                    ])
                                  ]),
                                  _c("v-row", { staticClass: "ml-3" }, [
                                    _c("label", [
                                      _vm._v(
                                        "End.: " +
                                          _vm._s(_vm.fundingAgency.address)
                                      )
                                    ])
                                  ]),
                                  _c("v-row", { staticClass: "ml-3" }, [
                                    _c("label", [
                                      _vm._v(
                                        "E-mail.: " +
                                          _vm._s(_vm.fundingAgency.email)
                                      )
                                    ])
                                  ]),
                                  _c("v-row", { staticClass: "ml-3" }, [
                                    _c("label", [
                                      _vm._v(
                                        "Tel.: " +
                                          _vm._s(_vm.fundingAgency.phone)
                                      )
                                    ])
                                  ]),
                                  _c("v-row", { staticClass: "ml-3" }, [
                                    _c("label", [
                                      _vm._v(
                                        "CNPJ: " +
                                          _vm._s(_vm.fundingAgency.cnpj)
                                      )
                                    ])
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "agencia",
                                  attrs: { cols: "1" }
                                },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { left: "" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        outlined: "",
                                                        small: "",
                                                        color: "red"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.openDialogDel(
                                                            _vm.fundingAgency
                                                          )
                                                        }
                                                      }
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [_vm._v("delete")]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ])
                                    },
                                    [
                                      _c("span", [
                                        _vm._v("Remover agência de fomento")
                                      ])
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "agencia",
                                  attrs: { cols: "1" }
                                },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { left: "" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        outlined: "",
                                                        small: "",
                                                        color: "orange"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.openDialog(
                                                            _vm.fundingAgency
                                                          )
                                                        }
                                                      }
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [_vm._v("edit")]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ])
                                    },
                                    [
                                      _c("span", [
                                        _vm._v("Editar a agência de fomento")
                                      ])
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "agencia",
                                  attrs: { cols: "1" }
                                },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { left: "" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        outlined: "",
                                                        small: "",
                                                        color: "green"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.openDialogAR(
                                                            _vm.fundingAgency
                                                          )
                                                        }
                                                      }
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [_vm._v("add")]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ])
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "Adicionar recurso financeiro para a agência"
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-divider")
                ],
                1
              ),
              _c(
                "v-flex",
                [
                  _c("v-data-table", {
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.list,
                      "hide-default-footer": "",
                      "no-data-text": "Não há recursos cadastrados",
                      loading: false,
                      "calculate-widths": true
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item",
                        fn: function(props) {
                          return [
                            _c("tr", [
                              _c("td", { staticClass: "text-xs-left" }, [
                                _vm._v(_vm._s(props.item.begin))
                              ]),
                              _c("td", { staticClass: "text-xs-left" }, [
                                _vm._v(_vm._s(props.item.end))
                              ]),
                              _c("td", { staticClass: "text-xs-left" }, [
                                _vm._v(_vm._s(props.item.value))
                              ]),
                              _c(
                                "td",
                                { staticClass: "text-xs-left" },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { left: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          outlined: "",
                                                          "x-small": "",
                                                          color: "orange"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.openDialogUpdate(
                                                              props.item
                                                            )
                                                          }
                                                        }
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { small: "" } },
                                                      [_vm._v("edit")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _c("span", [
                                        _vm._v("Editar recurso financeiro")
                                      ])
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "td",
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { left: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          outlined: "",
                                                          "x-small": "",
                                                          color: "red"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.openDialogDelAR(
                                                              props.item
                                                            )
                                                          }
                                                        }
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { small: "" } },
                                                      [_vm._v("delete")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _c("span", [
                                        _vm._v("Excluir recurso financeiro")
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "800" },
          on: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              _vm.dialog = false
            }
          },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c("AgencyDialogUpdate", {
            attrs: { object: _vm.agency },
            on: { "close-dialog": _vm.closeDialog }
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "800" },
          on: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              _vm.dialogDel = false
            }
          },
          model: {
            value: _vm.dialogDel,
            callback: function($$v) {
              _vm.dialogDel = $$v
            },
            expression: "dialogDel"
          }
        },
        [
          _c("AgencyDialogDel", {
            attrs: { object: _vm.agency },
            on: { "close-dialog": _vm.closeDialogDel }
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "800" },
          on: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              _vm.dialogAR = false
            }
          },
          model: {
            value: _vm.dialogAR,
            callback: function($$v) {
              _vm.dialogAR = $$v
            },
            expression: "dialogAR"
          }
        },
        [
          _c("AvailableResourceDialog", {
            attrs: { object: _vm.agency },
            on: { "close-dialog": _vm.closeDialogAR }
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "800" },
          on: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              _vm.dialogDelAR = false
            }
          },
          model: {
            value: _vm.dialogDelAR,
            callback: function($$v) {
              _vm.dialogDelAR = $$v
            },
            expression: "dialogDelAR"
          }
        },
        [
          _c("AvailableResourceDialogDel", {
            attrs: { object: _vm.availableResource },
            on: { "close-dialog": _vm.closeDialogDelAR }
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "800" },
          on: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              _vm.dialogUpdate = false
            }
          },
          model: {
            value: _vm.dialogUpdate,
            callback: function($$v) {
              _vm.dialogUpdate = $$v
            },
            expression: "dialogUpdate"
          }
        },
        [
          _c("AvailableResourceDialogUpdate", {
            attrs: { object: _vm.availableResource },
            on: { "close-dialog": _vm.closeDialogUpdate }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }