var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-card",
        { attrs: { outlined: "" } },
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "12" } },
                    [
                      _c("h4", { staticClass: "title" }, [
                        _vm._v("Remover Recurso")
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-card",
                { attrs: { outlined: "" } },
                [
                  _c("v-card-title", [
                    _c("h5", [_vm._v("Deseja realmente apagar o recurso?")])
                  ]),
                  _c("v-card-text", [
                    _c("p", [_vm._v("Início: " + _vm._s(_vm.resource.begin))]),
                    _c("p", [_vm._v("Término: " + _vm._s(_vm.resource.end))]),
                    _c("p", [_vm._v("Valor R$: " + _vm._s(_vm.resource.value))])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c(
                "v-row",
                { attrs: { justify: "center", align: "center", wrap: "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "6" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "green",
                            outlined: "",
                            disabled: _vm.disable
                          },
                          on: {
                            click: function($event) {
                              return _vm.asyncDelAvailableResource()
                            },
                            keypress: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k($event.keyCode, "esc", 27, $event.key, [
                                  "Esc",
                                  "Escape"
                                ])
                              ) {
                                return null
                              }
                              return _vm.closeDialog()
                            }
                          }
                        },
                        [_vm._v("Apagar")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "6" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "red",
                            outlined: "",
                            disabled: _vm.disable
                          },
                          on: {
                            keypress: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k($event.keyCode, "esc", 27, $event.key, [
                                  "Esc",
                                  "Escape"
                                ])
                              ) {
                                return null
                              }
                              return _vm.closeDialog()
                            },
                            click: function($event) {
                              return _vm.closeDialog()
                            }
                          }
                        },
                        [_vm._v("Cancelar")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }