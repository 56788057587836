<template>
  <v-container fluid>
    <v-card outlined>
      <v-card-title>
        <v-row>
          <v-col cols="12" class="text-center">
            <h4 class="title">Remover Recurso</h4>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-card outlined>
          <v-card-title>
            <h5>Deseja realmente apagar o recurso?</h5>
          </v-card-title>
          <v-card-text>
            <p>Início: {{ resource.begin }}</p>
            <p>Término: {{ resource.end }}</p>
            <p>Valor R$: {{ resource.value }}</p>
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-row justify="center" align="center" wrap>
          <v-col cols="6" class="text-center">
            <v-btn
              color="green"
              outlined
              :disabled="disable"
              @click="asyncDelAvailableResource()"
              @keypress.esc="closeDialog()"
              >Apagar</v-btn
            >
          </v-col>
          <v-col cols="6" class="text-center">
            <v-btn
              color="red"
              outlined
              :disabled="disable"
              @keypress.esc="closeDialog()"
              @click="closeDialog()"
              >Cancelar</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "AvailableResourceDialogDel",
  props: ["object"],
  data() {
    return {
      resource: {},
      disable: false,
    };
  },
  created() {
    this.resource = this.object;
  },
  methods: {
    ...mapActions("availableResource", ["ActionDelAvailableResource"]),

    async asyncDelAvailableResource() {
      try {
        this.disable = true;
        await this.ActionDelAvailableResource(this.resource.id);
        this.closeDialog(this.resource.id);
      } catch (error) {
        console.log("Erro ao remover recurso", error);
      } finally {
        this.disable = false;
      }
    },

    closeDialog(id) {
      this.resource = {};
      this.$emit("close-dialog", id);
    },
  },
  watch: {
    object(newValue) {
      this.resource = newValue;
    },
  },
};
</script>

<style>
</style>