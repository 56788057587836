<template>
  <v-container fluid>
    <v-card outlined>
      <v-card-title>
        <v-row>
          <v-col cols="12" class="text-center">
            <h4 class="title">Inserir Novo Recurso</h4>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="4" class="text-center">
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :autofocus="true"
                  outlined
                  v-model="beginDate"
                  label="Data de início"
                  prepend-inner-icon="calendar_today"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @keypress.esc="closeDialog()"
                  @keypress.enter="postAsyncAvailableResources()"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="beginDate"
                @input="menu2 = false"
                locale="br"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="4" class="text-center">
            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  v-model="endDate"
                  label="Data de término"
                  prepend-inner-icon="calendar_today"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @keypress.esc="closeDialog()"
                  @keypress.enter="postAsyncAvailableResources()"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="endDate"
                @input="menu1 = false"
                locale="br"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="4" class="text-center">
            <v-text-field
              outlined
              v-model="value"
              label="Valor do Recurso"
              prepend-inner-icon="attach_money"
              @keypress.esc="closeDialog()"
              @keypress.enter="postAsyncAvailableResources()"
              :rules="[numberRule]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-progress-circular
              indeterminate
              color="green"
              v-show="disable == true"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row justify="center" align="center" wrap>
          <v-col cols="12" class="text-center">
            <v-btn
              color="green"
              outlined
              :disabled="disable"
              @click="postAsyncAvailableResources()"
              @keypress.esc="closeDialog()"
              >Adicionar</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "AvailableResourceDialog",
  props: ["object"],
  data() {
    return {
      disable: false,
      response: {},
      agency: {},
      beginDate: new Date().toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10),
      value: 0,
      menu: false,
      menu1: false,
      menu2: false,
      modal: false,
      numberRule: (v) => {
        if (!isNaN(parseFloat(v))) return true;
        return "O campo é numérico!";
      },
    };
  },

  created() {
    this.agency = this.object;
  },

  methods: {
    ...mapActions("availableResource", ["ActionPostAvailableResource"]),

    async postAsyncAvailableResources() {
      if (this.beginDate !== "" && this.endDate !== "" && this.value > 0) {
        try {
          const body = {
            begin: this.beginDate,
            end: this.endDate,
            value: parseFloat(this.value),
          };
          this.disable = true;
          const res = await this.ActionPostAvailableResource({
            id: this.agency.id,
            body: body,
          });
          this.response = res.body;
          this.closeDialogCreated();
        } catch (error) {
          console.error("Erro ao inserir um novo recurso para a agência");
        } finally {
          this.disable = false;
        }
      }
    },

    closeDialog() {
      this.agency = {};
      this.$emit("close-dialog", null);
    },

    closeDialogCreated() {
      this.agency = {};
      this.$emit("close-dialog", this.response);
    },
  },

  watch: {
    object(newObject) {
      this.agency = newObject;
    },
  },
};
</script>

<style>
</style>